import CoreModule from "modules/Core/core-modules/CoreModule";
import { CAMPAIGNS } from "./consts";
import cells from "./state/cells";
import routes from "./routes";
import feed from "./state/feed";

const Campaigns = new CoreModule({
    name: CAMPAIGNS,
    initialState: feed?.getInitialFeedState(),
    cells,
    routes,
});

export default Campaigns;