import React, { useCallback, useMemo, useState } from 'react';
import CenteredCardScreen from 'modules/Core/components/ui-kit/Screen/CenteredCardScreen';
import AuthForm from '../../forms/AuthForm';
import useTabIndex from 'modules/Core/hooks/useTabIndex';
import NextButton from 'modules/Core/components/ui-kit/Button/NextButton';
import { formatPhoneNumberDiscretely } from 'modules/Core/utils/phone-number';
import styles from "./styles.module.scss";

const Login = ({ }) => {
    const { activeTabIndex, next } = useTabIndex();
    const [phoneNumber, setPhoneNumber] = useState('');

    const subheading = useMemo(() => ([
        "Type your phone number to authenticate",
        `Enter security code to verify ${phoneNumber} and login.`
    ][activeTabIndex]), [activeTabIndex, phoneNumber])

    const onCodeSent = useCallback((phoneNumber) => {
        next()
        setPhoneNumber(
            formatPhoneNumberDiscretely(phoneNumber)
        )
    }, [next, setPhoneNumber])

    return (
        <CenteredCardScreen
            cardHeader={"Login"}
            subHeader={subheading}
            cardClassName={styles.card}
            header={'People 4 Progress'}
            background
        >
            <AuthForm
                onCodeSent={onCodeSent}
                ButtonComponent={NextButton}
                className={styles.form}
            />
        </CenteredCardScreen>
    )
}

export default Login

