import { useMemo } from "react";
import CampaignsFeed from "modules/Campaigns/components/CampaignsFeed";
import Container from "modules/Core/components/ui-kit/Container";
import strapped from "modules/Core/higher-order-components/strapped";
import styles from "./styles.module.scss"
import { ItemComponent } from "modules/Campaigns/components/screens/CampaignsFeed/panels/FeedPanel";


const PartnerCampaignsFeed = strapped(CampaignsFeed,
    ({ partnerId }) => ({
        filters: useMemo(() => ({ partnerId }), [partnerId]),
        feedItemClassName: styles.feedItem,
        feedProps: useMemo(() => ({ ItemComponent, itemProps: { displayViewCampaign: true } }), [])

    })
);


const PartnerCampaignsTab = ({ partnerId }) => (
    <Container className={styles.container}>
        <PartnerCampaignsFeed partnerId={partnerId} />
    </Container>
);

export default PartnerCampaignsTab;

