import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import FundraisingScreen from "modules/Payments/components/FundraisingScreen"

const params = {
    title: "Support P4P",
    visionStatement: "You can make the difference we need! Help us cover running costs during our development period.",
    target: 500,
    background: "Everyone working on this project have dedicated a lot of time, effort and energy into the project. We're currently looking to raise enough funds to cover our running costs for the next few months."
}
const feedParams = {
    isPlatformDonation: true
}

export default strappedConnected(
    FundraisingScreen,
    {},
    {},
    ({ amountRaised = 20 }) => ({
        amountRaised,
        background: params.background,
        details: [{ name: 'Background', content: params.background }],
        target: params.target,
        title: params.title,
        header: "Donate to P4P",
        visionStatement: params.visionStatement,
        feedParams
    })
);  