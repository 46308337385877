
import cx from 'classnames';
import styles from "./styles.module.scss";

const Tag = ({ text, onDelete,children, className, grey }) => (
    <span className={cx(styles.container, className, {[styles.grey]: Boolean(grey)})}>
        {text ?? children} {onDelete && <span onClick={onDelete} className={styles.x} >X</span>}
    </span>
)


export default Tag
