import { useMemo } from 'react';
import Screen from 'modules/Core/components/ui-kit/Screen';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import fetchFeedData from 'modules/Payments/state/cells/fetchFeedData';
import { useOnLoad } from 'modules/Core/hooks/useOnLoad';
import Header from './Header';
import properties from './properties'
import styles from './styles.module.scss';

const FundraisingScreen = ({ header, target, amountRaised, visionStatement, title, ...props }) => {
    return (
        <Screen
            {...properties}
            header={header}
            headerClassName={styles.header}
            contentHeader={
                <Header
                    target={target}
                    amountRaised={amountRaised}
                    visionStatement={visionStatement}
                    title={title}
                />
            }
            {...props}
        />
    )
}

export default strappedConnected(FundraisingScreen,
    {
        feedData: fetchFeedData.selectors.getFeedData
    },
    {
        fetchFeedData: (params) => fetchFeedData.action({ params })
    },
    ({
        target,
        background,
        feedParams,
        feedData,
        fetchFeedData,
        details,
        ...props
    }) => {
        const amountRaised = useMemo(() => feedData?.total, [feedData]);
        useOnLoad(() => fetchFeedData( feedParams ), !feedData, [feedData, feedParams])
        const amountRemaining = useMemo(() => target && (target - amountRaised), [amountRaised, target]);
        return ({
            amountRemaining,
            tabProps: useMemo(() => ({
                background,
                feedParams,
                details
            }), [amountRaised, target, background, feedParams]),
            widgetProps: useMemo(() => ({
                amountRaised,
                target,
                amountRemaining
            }), [amountRaised, target, amountRemaining]),
            amountRaised
        })
    }
);