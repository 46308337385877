import CampaignMaintainerForm from '../../CampaignMaintainerForm';
import CenteredCardScreen from 'modules/Core/components/ui-kit/Screen/CenteredCardScreen';
import styles from "./styles.module.scss";

const MaintainerApplicationForm = ({ campaignId }) => (
    <CenteredCardScreen 
        header={'Campaign Maintainer Application'}
        cardClassName={styles.container}
        cardHeader={'Application Form'}
    >
        <CampaignMaintainerForm className={styles.form} campaignId={campaignId} />
    </CenteredCardScreen>
)

export default MaintainerApplicationForm;