import cx from "classnames";
import Drawer from "modules/Core/components/ui-kit/Drawer";
import RedirectButton from "modules/Core/components/ui-kit/RedirectButton";
import Container from "../ui-kit/Container";
import { SIDEBAR_HEADER } from "./const";
import styles from './styles.module.scss';
import AuthLinks from "modules/Auth/components/AuthLinks";

const Connected = () => <RedirectButton
text={"Logout"}
to={"/logout"}
nature={'grey-bg'}
/>

const Sidebar = ({ isOpen, close, children, className, authLinksProps }) => (
    <Drawer
        isOpen={isOpen}
        close={close}
        origin={"left"}
        size={"md"}
        header={SIDEBAR_HEADER}
        className={cx(styles.drawer, className)}
    >
        <Container flex col span spaceBetween maxHeight>
            {children}
            <Container mt3 flex col alignCenter maxWidth>
                <AuthLinks {...authLinksProps} className={cx(styles.auth, authLinksProps?.className)} Connected={Connected} />
            </Container>
        </Container>

    </Drawer>
)

export default Sidebar;