import { useMemo } from "react";
import { compact } from "lodash";
import Form from "modules/Core/components/form/Form";
import _schema, { partnerField } from "./schema";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import { useQueryParams } from "modules/Core/hooks/useStripQueryParams";
import { compactObject } from "modules/Core/utils/obj";

const parseForm = (data) => {
    return compactObject({
        partnerId: !data?.partner?.disabled && data?.partner?.id,
        name: data.name,
        vision: data.visionStatement,
        photo: data.profileImage,
        coverPhoto: data.coverImage,
        creators: compact(data?.creators?.map(creator => creator?.id)),
        maintainers: compact(data?.maintainers?.map(maintainer => maintainer?.id)),
        details: {
            contactInformation: data.contactInformation,
            values: data?.values?.map(val => ({
                text: val?.text,
                P4PValue: val?.id
            })),
            aims: data?.aims?.map(aim => ({
                aim: aim?.name,
                description: aim?.description,
            })),
            background: data.background,
            mission: data.mission,
            commitment: data.commitment,
            location: data?.location?.map(loc => loc?.key),
            tags: data?.tags?.map(tag => tag?.key?.id),
            links: data?.links
        }
    })

}

export default strappedConnected(
    Form,
    { partner: authorizeToken.selectors.getPartner },
    {},
    ({
        partner,
        onSubmit,
        initialState: _initialState,
        parseSchema = schema => schema,
        ...props
    }) => {

        const params = useQueryParams();

        const initialState = useMemo(() => ({
            partner: {
                ...partner,
                disabled: params?.asPartner != 'true'
            },
            ..._initialState
        }), [partner?.id, params]);

        const schema = useMemo(() => {
            if (!Boolean(partner?.id)) return parseSchema(_schema);
            return parseSchema({
                ..._schema,
                fields: {
                    ..._schema.fields,
                    partner: partnerField
                }
            })
        }, [partner?.id]);


        return ({
            schema,
            onSubmit: (form) => onSubmit(parseForm(form)),
            initialState,
            asyncInitialState: true,
            ...props,
        })
    }
)
