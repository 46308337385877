import Button from "modules/Core/components/ui-kit/Button";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import CreateAction from "../CreateAction";
import { createEntity, fetchEntity } from "modules/Actions/state/cells";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

const Component = ({ isOpen, open, close, additionalFields = {}, ...props }) => {
    return (
        <>
            <Button onClick={open} text={"Edit Action"} />
            <CreateAction
                isOpen={isOpen}
                close={close}
                {...props}
                header={"Edit Campaign Action"}
            />
        </>
    )
}

export default strappedConnected(
    Component,
    {initialState: (state, {id}) => fetchEntity.selector(id)(state) },
    { onCreate: ({ id, ...input }, callback) => createEntity.action({ input, callback, id }) },
    ({ onCreate, additionalFields, id, ...props }) => {
        const params = useParams();
        const campaignId = useMemo(() => params?.id ?? props.campaignId, [params, props?.id]);


        const { isOpen, open, close } = useIsOpen();
        const handleSubmit = useCallback((data) => {
            onCreate({
                ...data,
                campaign: campaignId,
                id
            }, (...args) => {
                close();
            })
        }, [additionalFields, id]);

        return ({
            isOpen,
            open,
            close,
            onSubmit: handleSubmit
        })
    }
);