import { useMemo } from "react";
import CampaignName from "../../CampaignName";
import strappedConnected from "modules/Core/higher-order-components/strappedConnected";
import { useOnLoad } from "modules/Core/hooks/useOnLoad";
import { fetchEntity } from "modules/Campaigns/state/cells";
import { renameKey } from "modules/Core/utils/obj";
import withParams from "modules/Core/higher-order-components/withParams";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import CampaignFormScreen from "../CampaignFormScreen";
import styles from "./styles.module.scss";

const nameAdapter = (name) => <>Edit <span className={styles.name}>{name}</span>:</>;
const Component = withShouldRender(CampaignFormScreen)
const StrappedComponent = strappedConnected(
    Component,
    {
        entity: (state, { id }) => fetchEntity.selector(id)(state)
    },
    {
        fetchCampaign: (id) => fetchEntity.action({ id }),
    },
    ({ fetchCampaign, entity, id }) => {
        useOnLoad(() => fetchCampaign?.(id), !entity?.vision, [fetchCampaign, id]);
        const initialState = useMemo(() => parseInitialState(entity), [entity?.details]);
        return {
            title: useMemo(() => <CampaignName id={id} Wrapper='span' adapter={nameAdapter} />),
            entity: useMemo(() => entity && renameKey(entity, "description", "text"), [entity]),
            shouldRender: Boolean(entity?.isCampaignFounder),
            additionalInput: useMemo(() => ({ id }), [id]),
            successMessage: "Successfully updated campaign",
            initialState,
            parseSchema: ({ fields }) => ({
                header: `Edit ${entity?.name ?? 'campaign'}`,
                fields: {
                    ...fields,
                    partner: null,
                    creators: null,
                    maintainers: null,
                }
            })
        }
    }
)

const parseInitialState = (entity) => ({
    name: entity?.name,
    profileImage: entity?.photo,
    visionStatement: entity?.vision,
    coverImage: entity?.coverPhoto,
    aims: entity?.details?.aims?.map(({ id, aim, description }) => ({ id, name: aim, description })),
    background: entity?.details?.background,
    values: entity?.details?.values?.map(({ id, text }) => ({ id, text })),
    location: entity?.details?.location?.map(({ city, countryCode }) => ({ city, countryCode, key: { city, countryCode }, readable: `${city} (${countryCode})` }),),
    tags: entity?.details?.tags?.map(({ id, name }) => ({ id, name, key: { id, name }, readable: name })),
    contactInformation: entity?.details?.contactInformation,
    mission: entity?.details?.mission,
    commitment: entity?.details?.commitment,
    links: entity?.details?.links
})

export default withParams(StrappedComponent)