import { gql } from "graphql-request";
import { graphqlClient } from "modules/Core/middleware";

const loginMutation = gql`
  mutation login($phoneNumber: String!) {
    login(phoneNumber: $phoneNumber) {
      validationCode
    }
  }
`;

const validateUserQuery = gql`
  query user {
    user {
      phoneId
      id
      information {
        firstName
        lastName
        bio
        photo
      }
      settings {
        displayUserGuide
      }
      partner {
        id
        name
        kind
        info {
          image
        }
      }
    }
  }
`;

const validateLoginCodeMutation = gql`
  mutation validateCode($phoneNumber: String!, $code: String!) {
    validateCode(phoneNumber: $phoneNumber, code: $code) {
      token
    }
  }
`;

const setUserSettingsMutation = gql`
  mutation setUserSettings($input: UserSettingsInput) {
    setUserSettings(input: $input) {
      id
    }
  }
`;

const setUserInformationMutation = gql`
  mutation setUserInformation($input: UserInformationInput) {
    setUserInformation(input: $input) {
      id
    }
  }
`;

export const loginUser = async (phoneNumber) => {
  return graphqlClient.request(loginMutation, { phoneNumber });
};

export const validateToken = async () => {
  return graphqlClient.request(validateUserQuery);
};

export const validateLoginCode = async (phoneNumber, code) => {
  return graphqlClient.request(validateLoginCodeMutation, {
    phoneNumber,
    code,
  });
};

export const setUserSettings = async (input) => {
  return graphqlClient.request(setUserSettingsMutation, { input });
};

export const setUserInformation = async (input) => {
  return graphqlClient.request(setUserInformationMutation, { input });
};
