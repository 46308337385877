import CampaignPartnershipInvitationsFeed from "modules/Partners/submodules/campaign-partnership-invitations/components/CampaignPartnershipInvitationsFeed";
import PartnerCampaignsTab from "./PartnerCampaignsTab";
import PartnerFinancialsFeedTab from "./PartnerFinancialsFeedTab";
import PartnerInfoTab from "./PartnerInfoTab";
import BusinessPartnerSubscriptionTab from "./BusinessPartnerSubscriptionTab";
import BusinessPartnerAnalyticsTab from "./BusinessPartnerAnalyticsTab";
import PartnerPostsTab from "./PartnerPostsTab";
import { PARTNER_KINDS } from "modules/Partners/consts";
import Redirect from "modules/Core/components/navigation/Redirect";


const BUSINESS_PARTNER_TABS = [
    { title: "Info", Component: (props) => <PartnerInfoTab {...props} noDonations /> },
    { title: "Subscription", Component: BusinessPartnerSubscriptionTab },
    { title: "Analytics", Component: BusinessPartnerAnalyticsTab },
];

const PEER_PARTNER_TABS = [
    { title: "Posts", Component: PartnerPostsTab },
    { title: "Info", Component: PartnerInfoTab },
    { title: "Campaigns", Component: PartnerCampaignsTab },
    { 
        title: 'Fundraising', 
        Component: ({ partnerId }) => <Redirect to={`/partner/${partnerId}/fundraising`} />,
        financiallyActiveOnly: true
    },
    // { title: "Team Members", Component: PartnerTeamMembersTab },
    {
        title: "Financials",
        Component: PartnerFinancialsFeedTab,
        associatedOnly: true
    },
    {
        title: "Campaign Invitations",
        Component: CampaignPartnershipInvitationsFeed,
        associatedOnly: true
    }
]

export default {
    [PARTNER_KINDS.BUSINESS]: BUSINESS_PARTNER_TABS,
    [PARTNER_KINDS.PEER]: PEER_PARTNER_TABS
}