import CampaignsPosts from 'modules/Campaigns/components/CampaignPosts';
import CampaignsFeed from '../../CampaignsFeed';
import CampaignDetails from 'modules/Campaigns/components/CampaignDetails';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import withPanelNavigator from 'modules/Core/higher-order-components/withPanelNavigator';
import styles from './styles.module.scss';

export const SubcampaignsFeed = ({ id, ...props }) => <CampaignsFeed alignCenter flex column feedItemClassName={styles.nestedFeedItem} {...props} parentId={id} />

const PostsPanel = withPanelNavigator(
    CampaignsPosts,
    'right',
    {
        getRestrictionSelector: (props) => fetchEntity?.selectField(props?.id, 'isMember'),
        to: "./camp",
        onRestrictionMessage: "You must be a member to enter the campaign campsite."
    }
)


export default [

    {
        Component: SubcampaignsFeed,
        props: { className: styles.campaignsFeed },
        title: "Sub-Campaigns",
    },
    {
        Component: CampaignDetails,
        props: { className: styles.campaignDetails },
        title: "Campaign Details",
        className: styles.campaignDetailsPanel,

    },
    {
        Component: PostsPanel,
        props: { className: styles.campaignsPosts },
        title: "Campaign Posts",
    },
]