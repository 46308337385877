import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"
import joinCampaignCell from "modules/Campaigns/state/cells/joinCampaign"
import Button from "modules/Core/components/ui-kit/Button"
import { fetchEntity } from "modules/Campaigns/state/cells";
import useTurnaryMemo from "modules/Core/hooks/useTurnaryMemo";
import useDispatchWithLoader from "modules/Core/hooks/useDispatchWithLoader";
import authorizeToken from "modules/Auth/state/cells/authorizeToken";
import withShouldRender from "modules/Core/higher-order-components/withShouldRender";
import styles from "./styles.module.scss";

const JoinCampaignButton = ({ id, campaignId = id, ...props }) => {
    const isMember = useSelector(fetchEntity.selectField(campaignId, 'isMember'));
    const isCampaignFounder = useSelector(fetchEntity.selectField(campaignId, 'isCampaignFounder'));
    const [action, loading] = useDispatchWithLoader(joinCampaignCell.action)
    const dispatch = useDispatch();
    const [text, className] = useTurnaryMemo({
        condition: isMember,
        onTrue: ["", styles.joined],
        onElse: ["Join Campaign",]
    })

    const callback = useCallback(() => {
        dispatch(fetchEntity.action({ id: campaignId }))
    }, [campaignId, dispatch])

    const onClick = useCallback(() => action({ id: campaignId, activate: !isMember, callback }), [action, campaignId, isMember, callback])

    return (
        <JoinCampaignButton.Component
            text={text}
            onClick={onClick}
            className={className}
            loading={loading}
            disabled={isCampaignFounder && isMember}
            shouldRender={Boolean(!isMember)}
            {...props}
        />)
}

JoinCampaignButton.Component = withShouldRender(({ onClick, text, ...props }) => (
    <Button
        text={text}
        onClick={onClick}
        restrictionSelector={authorizeToken.selectors.isAuthorized}
        onRestrictionMessage={{
            message: "You must be logged in to join a campaign",
            link: {
                text: "Login Here",
                to: "/login"
            }
        }}
        {...props}
    />
));

export default JoinCampaignButton;