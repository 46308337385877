import { call, put } from 'redux-saga/effects'
import { initCell } from '../../../Core/core-modules/CoreModuleState/utils/cells'
import { AUTH } from '../../consts'
import { validateToken } from 'modules/Auth/middleware';
import { getToken } from 'modules/Core/utils/auth';
import { loading } from './loading';

const authorizeToken = initCell(AUTH, {
    name: "authorizeToken",
    selectors: {
        getIsLoading: (state) => state.isLoading,
        isAuthorized: (state) => state.isAuthorized,
        getPartner: (state) => state.partner,
        getUserInformation: (state) => state.information,
        getUserId: (state) => state.id,
    },
    reducer: (state, payload) => {
        state.isLoading = true;
    },
    sagas: {
        latest: function* ({ payload }) {
            try {
                if (!getToken()) return yield put(loading.action(false))
                const res = yield call(validateToken)
                const isSuccess = Boolean(res?.user?.id);
                if (isSuccess) yield put(authorizeToken.onSuccess(res?.user))
            } catch (e) {
            }
        }
    },
    successCell: {
        reducer: (state, {payload}) => {
            state.id = payload?.id;
            state.isAuthorized = Boolean(payload?.id);
            state.partner = payload?.partner;
            state.isLoading = false;
            state.information = payload?.information;
            state.settings = payload?.settings;
        },
    }
});

export default authorizeToken;
