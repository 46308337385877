import CoreModule from "modules/Core/core-modules/CoreModule";
import {DIALOG } from "./consts";
import cells from "./state/cells";
import AppDialog from "./components/AppDialog";

const Dialog = new CoreModule({
    name: DIALOG,
    initialState: {
        message: {},
    },
    cells,
    rootRender: () => <AppDialog /> 
});

export default Dialog;