import React, { useEffect, useMemo, useRef } from "react";
import Container from "modules/Core/components/ui-kit/Container";
import feed from "modules/Campaigns/state/feed";

const FeedComponent = feed.FeedComponent;

const CampaignsFeed = ({ parentId = null, feedItemClassName, feedProps, ...props }) => {
    const f = useMemo(() => ({ parentId, ...props?.filters }), [parentId]);
    const feedRef = useRef();
    useEffect(() => {
        if (feedRef.current) feedRef?.current?.fetch({renewStream: true});
    }, [parentId]);
    return (
        <Container relative {...props}>
            <FeedComponent
                feedItemClassName={feedItemClassName}
                filters={f}
                feedRef={feedRef}
                {...feedProps}
            />
        </Container>
    );
}

export default CampaignsFeed;