import { CAMPAIGNS } from "modules/Campaigns/consts";
import FeedModule from "modules/Core/core-modules/FeedModule";
import { fetchCampaign, fetchCampaigns, setCampaign } from "modules/Campaigns/middleware";

export default new FeedModule({ 
    name: CAMPAIGNS,  
    cellOptions: {
        createEntityCell: {
            requestHandler: setCampaign
        },
        fetchFeedCell: {
            requestHandler: fetchCampaigns
        }, 
        fetchEntityCell: {
            requestHandler: fetchCampaign
        }
    }
});