import Container from "modules/Core/components/ui-kit/Container"
import { useCallback, useMemo } from "react";
import Text from "modules/Core/components/ui-kit/Text";
import SchemaForm from "../../SchemaForm";
import { InputLabel } from "modules/Core/components/ui-kit/Input";
import styles from "./styles.module.scss";

const defaultRenderValueItem = (val, index) => {
    return (
        <Container key={index}>
            <Text>{val?.name}</Text>
        </Container>
    )
}

const SchemaFormArrayField = ({ fields, value = [], onChange, label, renderValueItem = defaultRenderValueItem, submitButtonText, ...props }) => {
    const onSubmit = useCallback((val) => {
        onChange([
            ...(value ?? []),
            val,
        ])
    }, [onChange, value]);

    const renderedValue = useMemo(() => value?.map((val, i) => renderValueItem(val, i)), [renderValueItem, value]);

    return (
        <Container border p1 relative className={styles.container}>
            <InputLabel label={label} />
            {renderedValue}
            <SchemaForm
                schema={{ fields }}
                onSubmit={onSubmit}
                fullScreen={false}
                submitButtonText={submitButtonText}
                headerProps={{
                    relative: true,
                    fixed: false
                }}
                footerProps={{
                    relative: true,
                    fixed: false,
                    className: styles.footer
                }}
                className={styles.wrapper}
                cardless={true}
                options={{
                    highlightable: false
                }}
                {...props}
            />
        </Container>
    )
};

export default SchemaFormArrayField;
