import { initCell } from 'modules/Core/core-modules/CoreModuleState/utils/cells';
import { SET_FILTERS } from '../consts';

export default (moduleName, {onErrorMessage }) => initCell(
    moduleName,
    {
        name: SET_FILTERS,
        selectors: {
            getFilters: (state) => state.filters
        },
        reducer: (state, {payload}) => {
            const {reset, ...filters} = payload;
            state.filters = {
                ...(!reset && state.filters),
                ...filters, 
            }
        },
    }
)