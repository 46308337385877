import { useMemo } from "react";
import { compact } from "lodash";
import Feed from "modules/Campaigns/components/CampaignsFeed";
import Title from "modules/Core/components/ui-kit/Title";
import Container from "modules/Core/components/ui-kit/Container";
import ExpandableFeedItem from "modules/Core/components/Feed/ExpandableFeedItem";
import Date from "modules/Core/components/ui-kit/Date";
import styles from "./styles.module.scss";
import CampaignFeedItemDetails from "../CampaignFeedItemDetails";

const Header = ({ parent, name, approved, toggle, photo, }) => {
    const heritage = useMemo(() => parent && compact([parent?.parent?.name, parent?.name])?.join(' / '), [parent]);
    return (
        <Container maxWidth className={styles.itemHeader} onClick={toggle}>
            <Container maxWidth flex alignCenter spaceBetween>
                <Container flex alignCenter>
                    <img src={photo} />
                    <Title Element='h4' >
                        {heritage}
                        {name}
                    </Title>
                </Container>
                <Date className={styles.approved} date={approved?.time} />
            </Container>
        </Container>
    )
}

export const ItemComponent = ({ name, parent, text, description, onClick, approved, photo, ...props }) => {
    return (
        <ExpandableFeedItem
            {...props}
            HeaderComponent={Header}
            headerProps={{
                name,
                parent,
                approved,
                photo

            }}
            relative
            ChildComponent={CampaignFeedItemDetails}
            onClick={() => onClick(props.id)}
        />
    );
}

const filters = { parentId: null };
const FeedPanel = ({ className, onClick }) => {
    const feedProps = useMemo(() => ({
        onClick,
        ItemComponent
    }), [onClick]);
    return (
        <Feed
            feedProps={feedProps}
            className={className}
            feedItemClassName={styles.feedItem}
            {...filters}
        />
    )
}

export default FeedPanel