import { useMemo } from "react";
import CreateCampaign from "../CampaignFormScreen"
import { useParams } from "react-router-dom";
import CampaignName from "../../CampaignName";
import styles from "./styles.module.scss";

const nameAdapter = (name) => name + ":";
const CreateSubcampaign = ({ }) => {
    let { id } = useParams();
    const title = useMemo(() => <><CampaignName id={id} className={styles.name} Wrapper='span' adapter={nameAdapter} />Create Sub-Campaign </>)
    const additionalInput = useMemo(() => ({ parentId: id }), [id])
    return (
        <CreateCampaign
            parentId={id}
            title={title}
            additionalInput={additionalInput}
            redirectRoute={`/campaigns/${id}`}
        />
    )
};

export default CreateSubcampaign;