import { call, put } from "redux-saga/effects";
import { initCell } from "modules/Core/core-modules/CoreModuleState/utils/cells";
import { PAYMENTS } from "modules/Payments/consts";
import { fetchFeedData } from "modules/Payments/middleware";

export default initCell(PAYMENTS, {
  name: "fetchFeedData",
  selectors: {
    getFeedData: (state) => state.feedData,
  },
  sagas: {
    latest: function* ({ payload }) {
      const res = yield call(fetchFeedData, payload?.params);
      return res?.feedData;
    },
    onCellSuccess: true,
  },
  successCell: {
    reducer: (state, { payload }) => {
      state.feedData = payload;
    },
  },
});
