import Container from 'modules/Core/components/ui-kit/Container';
import Feature from 'modules/Core/components/ui-kit/Feature';
import Checkbox from 'modules/Core/components/ui-kit/Input/Checkbox';
import Widget from 'modules/Core/components/ui-kit/Widget';
import Button from 'modules/Core/components/ui-kit/Button';
import styles from './styles.module.scss'

const PostsFilterWidget = ({...props}) => {
    return (
        <Widget defaultOpen={false} label={"Filter Posts"} {...props}>
            <Container flex col>
                <Feature
                    className={styles.filterFeature}
                    name={'Include Sub-Campaign Posts'}
                    content={<Checkbox />}
                    TitleElement='h4'
                    row
                    flex
                    alignCenter
                    spaceBetween
                />
                <Feature
                    className={styles.filterFeature}
                    name={'Categories'}
                    content={
                        <>
                            <Button text={'peace'} />
                            <Button text={'education'} />
                            <Button text={'dialogue '} />
                        </>
                    }
                    TitleElement='h4'
                    col
                    flex
                    spaceBetween
                />
            </Container>
        </Widget>
    )
}


export default PostsFilterWidget;