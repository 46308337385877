import { useMemo } from 'react';
import strappedConnected from 'modules/Core/higher-order-components/strappedConnected';
import Widget from 'modules/Core/components/ui-kit/Widget';
import IdentityCard from 'modules/Core/components/ui-kit/IdentityCard';
import Text from 'modules/Core/components/ui-kit/Text';
import { fetchEntity } from 'modules/Campaigns/state/cells';
import { CAMPAIGN_ROLES } from 'modules/Campaigns/consts';
import authorizeToken from 'modules/Auth/state/cells/authorizeToken';
import styles from './styles.module.scss';

const CampaignMembershipWidget = ({ rank, name, photo, ...props }) => {
    return (
        <Widget card size='md' {...props}>
            <IdentityCard
                name={name}
                image={photo}
                className={styles.id}
            >
                <Text uppercase>{rank}</Text>
            </IdentityCard>
        </Widget>
    )
}

export default strappedConnected(
    CampaignMembershipWidget,
    {
        roles: (state, { campaignId }) => fetchEntity?.selectNestedField(campaignId, 'membership.roles')(state),
        userInformation: authorizeToken?.selectors.getUserInformation,
        membershipInfo: (state, { campaignId }) => fetchEntity?.selectNestedField(campaignId, 'membership.information')(state),
    },
    {},
    ({ roles, membershipInfo }) => ({
        rank: useMemo(() => (
            roles?.includes(CAMPAIGN_ROLES.ROOT) ? 'Creator' : roles?.includes(CAMPAIGN_ROLES?.MAINTAINER) ? 'Maintainer' : 'Member'
        ), [roles]),
        name: useMemo(() => Boolean(membershipInfo?.firstName) ? `${membershipInfo?.firstName} ${membershipInfo?.lastName}` : "Anonymous User", [membershipInfo?.firstName]),
        photo: useMemo(() => membershipInfo?.photo, [membershipInfo?.photo])
    })
);