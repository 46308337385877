import cx from 'classnames';
import emblem from "../../assets/p4.png";
import styles from './styles.module.scss';

const P4PEmblem = ({ className, containerClassName, onClick }) => (
    <span className={containerClassName} onClick={onClick}>
        <img src={emblem} alt="P4P" className={cx(styles.emblem, className)} />
    </span>
);

export default P4PEmblem;