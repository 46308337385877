import PartnerField from "modules/Core/components/form/PartnerField";
import {
  LOCATIONS_QUERY,
  parseLocationsResult,
} from "modules/Core/components/form/schema/locations";
import {
  parseTagsResult,
  TAGS_QUERY,
} from "modules/Core/components/form/schema/tags";
import ValuesField from "modules/Core/components/form/ValuesField";
import Widget from "modules/Core/components/ui-kit/Widget";
import styles from "./styles.module.scss";

export const PartnerFieldWidget = ({
  value,
  onChange,
  className,
  label,
  header,
  ...props
}) => (
  <Widget
    card
    className={className}
    size="lg"
  >
    <PartnerField
      {...props}
      label={label}
      value={value}
      disabled={value?.disabled}
      onChange={onChange}
      cardClassName={styles.partnerCard}
      onClear={() =>
        onChange({
          ...value,
          disabled: true,
        })
      }
    />
  </Widget>
);

export const partnerField = {
  type: "partner",
  label: "Launch as partner",
  cardless: true,
  className: styles.partnerField,
  Component: (props) => <PartnerFieldWidget {...props} />,
};

export default {
  fields: {
    name: {
      required: true,
      type: "string",
      label: "Campaign Name",
    },
    visionStatement: {
      required: true,
      type: "textarea",
      label: "Vision Statement",
      rows: 7,
    },
    profileImage: {
      type: "image",
      label: "Campaign Profile Photo",
    },
    coverImage: {
      type: "image",
      label: "Campaign Cover Photo",
    },
    values: {
      type: "custom",
      label: "Campaign Values",
      Component: ValuesField,
    },
    aims: {
      type: "array",
      label: "Campaign Aims",
      submitButtonText: "Add Aim",
      fields: {
        name: {
          type: "string",
          label: "Aim",
        },
        description: {
          type: "textarea",
          label: "Aim Description",
        },
      },
    },
    background: {
      type: "textarea",
      label: "Campaign Background",
      rows: 18,
    },
    mission: {
      type: "textarea",
      label: "Mission Statement",
      rows: 7,
    },
    commitment: {
      type: "string",
      label: "Commitment",
    },
    location: {
      type: "async-select",
      label: "Location",
      searchable: true,
      query: LOCATIONS_QUERY,
      parseResult: parseLocationsResult,
    },
    tags: {
      type: "async-select",
      label: "Tags",
      searchable: true,
      query: TAGS_QUERY,
      parseResult: parseTagsResult,
    },
    contactInformation: {
      type: "object",
      label: "Contact Information",
      fields: {
        fullName: {
          type: "string",
          label: "Contact Name",
        },
        email: {
          type: "string",
          label: "Email",
        },
        phoneNumber: {
          type: "phone-number",
          label: "Phone",
        },
      },
    },
    links: {
      type: "array",
      label: "External Links",
      submitButtonText: "Add Link",
      fields: {
        name: {
          type: "string",
          label: "Link Name",
        },
        url: {
          type: "string",
          label: "Link URL",
        },
      },
    },
    creators: {
      label: "Campaign Creators",
      type: "users",
      buttonLabel: "Add Creator",
    },
    maintainers: {
      label: "Campaign Maintainers",
      buttonLabel: "Add Maintainer",
      type: "users",
    },
  },
};
