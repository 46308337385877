import { useCallback, useState } from "react";
import Button from "modules/Core/components/ui-kit/Button";
import ExpandableCard, { ExpandableCardComponent } from "modules/Core/components/ui-kit/Card/ExpandableCard";
import Container from "modules/Core/components/ui-kit/Container";
import Input, { InputLabel } from "modules/Core/components/ui-kit/Input";
import AsyncSelect from "modules/Core/components/ui-kit/Input/AsyncSelect";
import { FETCH_P4P_VALUES_QUERY } from "modules/P4P/sub-modules/Values/middleware";
import { useIsOpen } from "modules/Core/hooks/useIsOpen";
import styles from './styles.module.scss';

const ValuesField = ({ label, onChange, value, ...props}) => {
    const [customValue, setCustomValue] = useState(null);
    const {isOpen, open, close, toggle} = useIsOpen();

    const handleSelect = useCallback((v) => {
        onChange([
            ...(value ?? []),
            v
        ])
    }, [value, onChange]);

    const onAddCustomValue = useCallback(()=> {
        handleSelect({text: customValue})
        setCustomValue('')
        close();
    }, [customValue]);

    return (
        <>
            <InputLabel label={label} />
            <Container>
                {value?.map((v, i) => (
                    <ExpandableCard key={i} title={v?.text} />
                ))}
            </Container>
            <AsyncSelect
                label={'Select from P4P Values'}
                query={FETCH_P4P_VALUES_QUERY}
                parseResult={r => r?.feed?.entities?.map((v) => ({
                    readable: v.text,
                    key: {
                        id: v.id,
                        text: v.text,
                        intent: v.intent
                    },
                }))}
                value={value}
                onChange={handleSelect}
                {...props}
            />
            <ExpandableCardComponent
                isOpen={isOpen} 
                size='md' 
                title={'Add Custom Value'} 
                toggle={toggle}
                toggleButtonClassName={styles.toggleButton}
                openClassName={styles.open}
            >
                <Container flex col maxWidth p1 spaceAround maxHeight alignEnd>
                    <Input maxWidth onChange={setCustomValue} value={customValue} />
                    <Button onClick={onAddCustomValue}>Add Value</Button>
                </Container>
            </ExpandableCardComponent>
        </>
    )
}


export default ValuesField;