import feed from "../../state/feed";
import Card from "modules/Core/components/ui-kit/Card";
import Title from "modules/Core/components/ui-kit/Title";
import Text from "modules/Core/components/ui-kit/Text";
import styles from "./styles.module.scss";

const { FeedComponent } = feed;

const FeedItem = ({ text, intent }) => (
    <>
        <Card className={styles.card}>
            <Title>{text}</Title>
            <Text>{intent}</Text>
        </Card>
    </>
)

const P4PValuesFeed = ({ }) => (
    <FeedComponent ItemComponent={FeedItem} />
)

export default P4PValuesFeed