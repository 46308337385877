import FeedModule from "modules/Core/core-modules/FeedModule";
import { ALLIES } from "modules/Partners/consts";
import middleware, { onboardPartner } from "modules/Partners/middleware";

export default new FeedModule({
    name: ALLIES,
    cellOptions: {
        createEntityCell: {
            requestHandler: onboardPartner
        },
        fetchFeedCell: {
            requestHandler: middleware.ops.fetchFeed
        },
        fetchEntityCell: {
            requestHandler: middleware.ops.fetchEntity
        }
    }
});