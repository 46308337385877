import React, { useCallback, useEffect, useMemo, useState } from "react"
import AuthForm from "modules/Auth/components/forms/AuthForm"
import ConditionalContainer from "modules/Core/components/ui-kit/ConditionalContainer"
import { useIsAuthenticated } from "modules/Auth/hooks/useConnectedDispatch"
import UserInformationForm, { UserInformationFormSuccess } from "modules/Auth/components/UserInformationForm"
import strappedConnected from "modules/Core/higher-order-components/strappedConnected"
import setUserInformation from "modules/Auth/state/cells/setUserInformation"
import NextButton from "modules/Core/components/ui-kit/Button/NextButton"
import CenteredCardScreen from "modules/Core/components/ui-kit/Screen/CenteredCardScreen"
import { formatPhoneNumberDiscretely } from "modules/Core/utils/phone-number"
import useTabIndex from "modules/Core/hooks/useTabIndex"
import styles from "./styles.module.scss"

const subheaders = [
    "Type your phone number to authenticate",
    "Enter security code to verify {REPLACE} and login.",
    "Authenticated! Now enter some profile details (all fields are optional).",
    "",
]

const RegisterIndividualScreen = ({ setUserInformation }) => {
    const isAuthenticated = useIsAuthenticated()
    const { activeTabIndex, next, setActiveTabIndex } = useTabIndex();

    const [phoneNumber, setPhoneNumber] = useState('');

    const subheading = useMemo(() => subheaders[activeTabIndex].replace('{REPLACE}', phoneNumber), [activeTabIndex, phoneNumber])

    const onCodeSent = useCallback((phoneNumber) => {
        next()
        setPhoneNumber(
            formatPhoneNumberDiscretely(phoneNumber)
        )
    }, [next, setPhoneNumber])

    useEffect(() => {
        isAuthenticated && setActiveTabIndex(2)
    }, [isAuthenticated])

    return (
        <CenteredCardScreen
            cardHeader='Register as Individual'
            nextText='Next'
            subHeader={subheading}
            header='Register'
            className={styles.container}
            background
            back
        >
            <ConditionalContainer shouldRender={Boolean(!isAuthenticated)}>
                <AuthForm
                    redirect={false}
                    onCodeSent={onCodeSent}
                    className={styles.authForm}
                    ButtonComponent={NextButton}
                />
            </ConditionalContainer>
            <ConditionalContainer className={styles.contentContainer} maxHeight span flex col center maxWidth shouldRender={Boolean(isAuthenticated)}>
                <UserInformationForm
                    className={styles.form}
                    SubmitButton={NextButton}
                    SuccessComponent={UserInformationFormSuccess}
                    onSubmit={setUserInformation}
                    fullScreen={false}
                    onSuccess={() => setActiveTabIndex(3)}
                />
            </ConditionalContainer>
        </CenteredCardScreen>
    )
};

export default strappedConnected(
    RegisterIndividualScreen,
    {},
    {
        setUserInformation: (form, callback) => setUserInformation.action({ input: form, callback })

    }

);