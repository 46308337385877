import WidgetButton from 'modules/Core/components/ui-kit/Widget/WidgetButton';
import DonationsFeed from '../DonationsFeed';
import Container from 'modules/Core/components/ui-kit/Container';
import FeaturesWidget from 'modules/Core/components/ui-kit/Widget/FeaturesWidget';
import Features from 'modules/Core/components/ui-kit/Features';

const DonatationButton = () => (
    <WidgetButton
        text="Donate Now"
        to='./donate'
    />
);
const DonationsFeedTab = ({ feedParams }) => (
    <Container>
        <DonationsFeed {...feedParams} />
    </Container>
);

const FundraisingProgressWidget = ({ amountRaised, target, amountRemaining, ...props }) => (
    <FeaturesWidget
        label="Fundraising Progress"
        features={[
            { name: 'Amount Raised', content: '£' + (amountRaised ?? 0) },
            { name: 'Fundraising Target', content: target && ('£' + target) },
            { name: 'Amount Remaining', content: amountRemaining && ('£' + amountRemaining) }
        ]}
        row
        {...props}
    >
    </FeaturesWidget>
);

export default {
    tabs: [
        {
            title: 'Donations',
            Component: DonationsFeedTab
        },
        {
            title: 'Details',
            Component: ({ details }) => <Features card features={details} />
        }
    ],
    back: {
        text: 'Back',
        navigate: -1
    },
    rightWidgets: [{
        Component: () => <DonatationButton />,
        gridRow: 2,
    }],
    leftWidgets: [
        { Component: (props) => <FundraisingProgressWidget {...props} />, gridRow: 4 },
    ]

}