import { useMemo } from 'react';
import cx from 'classnames';
import feed from 'modules/Posts/state/feed';
import Container from 'modules/Core/components/ui-kit/Container';
import FeedItem from 'modules/Core/components/Feed/FeedItem';
import Footer from 'modules/Core/components/ui-kit/layout/Footer';
import Date, { formatDateTime } from 'modules/Core/components/ui-kit/Date';
import Text from 'modules/Core/components/ui-kit/Text';
import Attachment from 'modules/Core/components/ui-kit/Attachment';
import IdentityCard from 'modules/Core/components/ui-kit/IdentityCard';
import ConditionalContainer from 'modules/Core/components/ui-kit/ConditionalContainer';
import Dot from 'modules/Core/components/ui-kit/Dot';
import styles from './styles.module.scss';

const FeedItemComponent = ({ member, createdAt, text, attachments, className, displayAttachments = true, userInformation: userInfo, ...props }) => {
    const [partnerName, partnerImage] = useMemo(() => ([member?.campaignPartner?.partner?.name, member?.campaignPartner?.partner?.info?.image]), [member])
    const [authorName, authorImage] = useMemo(() => ([userInfo?.firstName ? `${userInfo?.firstName} ${userInfo?.lastName}` : "anonymous", userInfo?.photo]), [userInfo?.firstName])

    return (
        <FeedItem {...props} className={cx(styles.feedItem, className)} relative shouldRenderText={false}>
            <Container className={styles.postBodyContainer} maxWidth flex >
                <Container>
                    <Text text={text} />
                </Container>
                <ConditionalContainer className={styles.attachmentsContainer} shouldRender={displayAttachments} row>
                    {attachments?.map(({ type, url }, index) =>
                        type.includes("image") ? (
                            <img key={index} src={url} alt="attachment" />
                        ) : (
                            <Attachment key={index} file={url} />
                        )
                    )}
                </ConditionalContainer>
            </Container>
            <Footer absolute spaceBetween flex alignCenter className={styles.footer}>
                <Date fs75 grey date={createdAt} formatter={formatDateTime} />
                <Container flex row alignCenter>
                    {partnerName && (
                        <IdentityCard
                            TitleElement='h5'
                            name={partnerName}
                            image={partnerImage}
                            className={styles.memberId}
                        />
                    )}
                    <Dot shouldRender={Boolean(partnerName && authorName)} margin={'0rem 1rem'} size={'0.5rem'} color={'#808080e6'} />
                    {authorName && (
                        <IdentityCard
                            TitleElement='h5'
                            name={authorName}
                            image={authorImage}
                            className={styles.memberId}
                        />)}
                </Container>
            </Footer>
        </FeedItem>
    )
}

const PostsFeed = ({ filters, onClick, feedItemClassName, itemProps }) => (
    <Container className={styles.container} flex col alignCenter>
        <feed.FeedComponent
            filters={filters}
            onClick={onClick}
            ItemComponent={FeedItemComponent}
            feedItemClassName={feedItemClassName}
            itemProps={itemProps}
        />
    </Container>
)

export default PostsFeed;