import useSchemaFormFields from "modules/Core/components/form/Form/hooks/useSchemaFormFields"
import { useMemo } from "react";

const MultiTabForm = ({ tabs, activeTabIndex, options }) => {
    const [tabFields, Component] = useMemo(() => ([
        tabs[activeTabIndex]?.fields,
        tabs[activeTabIndex]?.Component
    ]), [activeTabIndex, tabs]);

    const { fields } = useSchemaFormFields({ fields: tabFields }, {}, options);

    const children = useMemo(() => fields || <Component />, [fields, Component]);
    return (
        <>
            {children || 'hey'}
        </>
    )
}

export default MultiTabForm