import Card from 'modules/Core/components/ui-kit/Card';
import Drawer from 'modules/Core/components/ui-kit/Drawer';
import ApproveProspectiveCampaign from 'modules/Campaigns/components/ApproveProspectiveCampaign';
import useFocusedId from 'modules/Core/hooks/useFocusedId';
import CampaignDetails from '../../CampaignDetails';
import CampaignDetailsTab from '../../CampaignDetails/CampaignDetailsTab';
import RejectProspectiveCampaign from 'modules/Campaigns/components/RejectProspectiveCampaign';
import ProspectiveCampaignsFeed from 'modules/Campaigns/sub-modules/prospective-campaigns/components/ProspectiveCampaignsFeed';
import styles from './styles.module.scss';

const ProspectiveCampaignsCard = ({ className, campaignId, Container = Card }) => {
    const { focusedId, focus, unFocus } = useFocusedId();
    return (
        <Container
            header={"Prospective Sub-Campaigns"}
            className={className}
        >
            <ProspectiveCampaignsFeed
                parentId={campaignId}
                onClick={focus}
            />
            <Drawer
                origin={"left"}
                isOpen={Boolean(focusedId)}
                close={unFocus}
                header={"Prospective sub-campaign details"}
            >
                <Container>
                    <CampaignDetails id={focusedId} >
                        <ApproveProspectiveCampaign id={focusedId} callback={unFocus} />
                        <RejectProspectiveCampaign id={focusedId} callback={unFocus} />
                        <CampaignDetailsTab shouldLoad={false}  id={focusedId} />
                    </CampaignDetails>
                </Container>
            </Drawer>
        </Container>
    )
}
export default ProspectiveCampaignsCard;