import SchemaForm from 'modules/Core/components/form/Form/SchemaForm';
import { campaignMembersipInformationFormFields } from 'modules/Campaigns/components/SetCampaignMembershipInfoModal';
import styles from './styles.module.scss';

const fields = {
    ...campaignMembersipInformationFormFields,
    text: {
        label: 'Additional Application Notes',
        type: 'textarea',
        rows: 5,
    }
}

export default ({ onSubmit, className, initialState }) => (
    (
            <SchemaForm
                schema={{
                    fields,
                    // header: 'Campaign Maintainer Application Form',
                    subheader: "Apply to become a maintainer for this campaign."
                }}
                onSubmit={onSubmit}
                fullScreen={false}  
                className={className}
                initialState={initialState}
                asyncInitialState={true}
            />
    )
)
