import feed from "../feed";
import approvePerspectiveCampaign from "./approvePerspectiveCampaign";
import joinCampaign from "./joinCampaign";
import setCampaignMembershipInformation from "./setCampaignMembershipInformation";

export const {
    setFilters,
    createEntity,
    fetchFeed,
    fetchEntity,
} = feed.cells;

export default {
    createEntity,
    fetchFeed,
    fetchEntity,
    joinCampaign,
    setFilters,
    approvePerspectiveCampaign,
    setCampaignMembershipInformation
}